import React, { useEffect, useState, useContext, } from "react";
import { Helmet } from "react-helmet";
import AOS from "aos";
import "aos/dist/aos.css";
import Contact from 'components/home/contact';
import ExoticRentalCar from "components/home/exoticRentalCar";
import DriveTheDream from "components/home/driveTheDream";
import StoriesFromCustomer from "components/home/storiesFromCustomer";
import axios from "axios";
import AskQuestion from "components/home/askQuestion";
import ExoticRental16Car from "components/home/exoticRental16Car";


export default function RollsRoyceDawn() {
    const [selectedMainImage, setSelectedMainImage] = useState('../../../../monaco_assets/rolls-royce-dawn-1.svg')
    const [selectedVehicle, setSelectedVehicle] = useState([
        "../../../../monaco_assets/rolls-royce-dawn-2.png",
        "../../../../monaco_assets/rolls-royce-dawn-3.png",
        "../../../../monaco_assets/rolls-royce-dawn-4.png",
        "../../../../monaco_assets/rolls-royce-dawn-5.png",
    ])

    const [faqItems, setFaqItems] = useState([
        {
            id: 0,
            question: "how much does it cost to rent a rolls royce?",
            answer: "the rental cost varies based on the duration and specifics of your rental agreement. please contact us or fill up our enquiry form for detailed rates and special offers.",
            isCollapsed: true
        }, {
            id: 1,
            question: "what are the requirements to rent a rolls royce?",
            answer: "to rent a rolls royce dawn, you must be at least 25 years old, possess a valid driver’s license, and carry full insurance coverage. a security deposit is also required at the time of rental.",
            isCollapsed: true
        }, {
            id: 2,
            question: "are there any mileage restrictions when hiring the rolls royce dawn?",
            answer: "yes, our rentals come with a mileage cap which varies depending on the rental package you choose.additional miles can be purchased at the time of rental.",
            isCollapsed: true
        }, {
            id: 3,
            question: "can i rent a rolls royce for my wedding?",
            answer: "absolutely! our rolls royce wedding rental adds elegance to your special day. rent through monaco experiences for an unforgettable entrance.",
            isCollapsed: true
        }, {
            id: 4,
            question: "what happens if the rolls royce is damaged during my rental period?",
            answer: "in the event of damage, you should immediately report it to us. you  be liable for repair costs up tothe amount of the security deposit, depending on the nature of the damage and your insurance coverage.",
            isCollapsed: true
        }, {
            id: 5,
            question: "where can i find rolls royce rentals near me?",
            answer: "monaco experiences provides rolls royce rental services in los angeles, new york, boston, washington dc,new jersey, and more. we ensure our luxury car rental services are easily accessible to meet your needs, no matter where you are. contact us to discuss further.",
            isCollapsed: true
        }
    ]);

    const toggleCollapse = (id) => {
        setFaqItems((prevState) =>
            prevState.map((item) =>
                item.id === id ? { ...item, isCollapsed: !item.isCollapsed } : item
            )
        );
    };

    const handleOpenEnquiry = () => {
        const element = document.querySelector("#enquiry-form");
        if (element) {
            element.scrollIntoView({ behavior: "smooth" });
        }
    };

    return (
        <>
            <Helmet>
                <meta charSet="utf-8" />
                <title>
                    Rolls Royce Rental | Monaco Experiences Luxury Car Rental | NJ,NY,LA
                </title>
                <meta name="description" content="Get a customised quotation, Experience ultimate luxury with a Rolls Royce Dawn, Rolls Royce Wraith rental from Monaco Experiences for weddings or special occasions. Serving NJ,NY,LA " />
                <meta name="keywords" content="rolls royce rental, rent rolls royce, rolls royce wedding rental" />
                <link rel="canonical" href="https://monacoexperiences.com/rolls-royce-dawn-rental" />
            </Helmet>

            <div class="container mt-120 md-mt-130">

                <div class="feature-blog-three align-items-center justify-content-between" data-aos="fade-up" data-aos-duration="1200" style={{ display: 'flex', padding: '0px', margin: '0px 5px' }}>
                    <div style={{ display: 'flex', padding: '0px', alignItems: 'center' }}>
                        <img src="../../../../monaco_assets/logo5.png" alt="Rolls-Roys-Down" style={{ height: '45px' }} />
                        <div style={{ lineHeight: '25px', marginLeft: '5px' }}>
                            <p>
                                <span class="font-montserrat fs-20 fw-700" style={{ color: '#fff' }}>ROLLS ROYCE DAWN</span>
                            </p>
                            <p class='font-montserrat fw-500 fs-12' style={{ color: '#fff', lineHeight: 1.2 }}>
                                6.6.l twin turbo v12  |  536 horsepower  |  actual msrp : $ 450,400
                            </p>
                        </div>
                    </div>
                    <a style={{ cursor: "pointer", color: '#fff', background: '#B88B2B', borderRadius: '5px', cursor: 'pointer' }} onClick={handleOpenEnquiry} class="read-more">
                        <span class="font-montserrat fs-20" style={{ margin: 'auto', padding: '12px 15px 15px', color: '#000' }}>inquire</span>
                    </a>
                </div>
                {/* </div> */}
                <div class="row mt-20 align-items-start justify-content-between" data-aos="fade-up" data-aos-duration="1200">

                    <div class="col-lg-12">
                        <div class=" font-montserrat">

                            <div style={{ cursor: "pointer", display: 'flex', justifyContent: 'center' }} class="post-meta">
                                <img src={selectedMainImage === '' ? selectedVehicle.main_image : selectedMainImage} alt={selectedVehicle.make} style={{ borderRadius: '5px' }} />
                            </div>

                            <div id='div11' class="mt-10" style={{ display: 'flex', justifyContent: 'space-between', overflowY: 'auto' }}>
                                {selectedVehicle && selectedVehicle.map(item =>
                                    <div style={{ cursor: "pointer", margin: '5px' }}>
                                        <img src={item} onClick={() => setSelectedMainImage(item)} style={{ minWidth: '170px', borderRadius: '5px' }} alt={""} />
                                    </div>
                                )}
                            </div>
                        </div>
                    </div>
                </div>

                <div class="row align-items-start justify-content-between mt-20" data-aos="fade-up" data-aos-duration="1200">
                    <div class="col-lg-12">
                        <h1 class="font-montserrat fs-40 fw-600" style={{ color: '#fff', textAlign: 'center' }}>
                            rolls royce rental tops the monaco list
                        </h1>
                        <p class="font-montserrat fw-300 fs-16 mt-10" style={{ color: '#fff' }}>
                            in the realm of <span class="fw-700">luxury</span> car rentals, the rolls royce dawn stands as a symbol of unmatched elegance and sophistication. as the most booked car in our fleet, the dawn captivates with its exquisite design, lavish comfort, and powerful performance, making it the <span class="fw-700">ideal choice for weddings</span>, corporate events, and other special occasions.
                        </p>

                        <p class="font-montserrat fw-300 fs-16 mt-10" style={{ color: '#fff' }}>
                            picture your wedding day, every detail meticulously planned, and now, the perfect touch: a rolls royce wedding rental from monaco experiences. this isn't just a ride; <span class="fw-700">it's an experience</span> that wraps you in unmatched luxury. feel the smooth leather seats, the whisper-quiet v12 engine, and the aura of prestige as you glide to your venue.
                        </p>

                        <p class="font-montserrat fw-300 fs-16 mt-10" style={{ color: '#fff' }}>
                            statistics show that <span class="fw-700">67%</span> of our clients select the rolls royce dawn for weddings, making it a <span class="fw-700">top choice</span> for such occasions. imagine arriving at your wedding in this stunning vehicle making your <span class="fw-700">dream come true</span> additionally the plush orange interior adds a unique touch of luxury that will make you never want to get out. experience the ultimate in luxury car rental with the rolls royce dawn. <span class="fw-700">elevate your next event</span> and create unforgettable memories.
                        </p>


                        <p class="font-montserrat fw-300 fs-16 mt-10" style={{ color: '#fff', fontStyle: 'italic' }}>
                            <span class="fw-700">don't settle for ordinary!</span> elevate your special day with rolls royce dawn, and let monaco experiences make your special moments truly unforgettable 🔥
                        </p>
                    </div>
                </div>
            </div>



            <div class="font-montserrat feature-blog-one blog-page-bg" data-aos="fade-up" data-aos-duration="1200">
                <div class="container section-heading text-center">
                    <h2 class="font-montserrat fs-40 fw-600" style={{ color: '#ffffff' }}>
                        our ultimate exotic car rental fleet
                    </h2>
                    <p class="font-montserrat fs-20 fw-200" style={{ color: '#ffffff' }}>
                        classy and luxury rental cars  : discover our unique <span style={{ fontWeight: 700 }}>showroom experience</span>
                    </p>
                </div>
                <ExoticRental16Car />
            </div>
            <div className="mt-30" id="enquiry-form">
                <Contact />
            </div>

            <div style={{ background: '#111' }}>
                <div style={{ background: '#111' }}>
                    <div className="container pt-30 pb-30">
                        <div style={{ textAlign: 'center' }}>
                            <h2 className="font-montserrat fs-40 fw-600" style={{ color: '#FFF' }}>
                                frequently asked questions
                            </h2>
                            <p className="font-montserrat mt-10" style={{ color: '#BABABA' }}>
                                these are the questions we've received from our real-time customers about renting an exotic car from monaco experiences.
                            </p>
                        </div>

                        {faqItems.map((item) => (
                            <div key={item.id} className="container mt-40">
                                <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                                    <h3
                                        className="font-montserrat fs-20 fw-500"
                                        onClick={() => toggleCollapse(item.id)}
                                        style={{ color: '#BABABA', cursor: 'pointer' }}
                                    >
                                        {item.question}
                                    </h3>
                                    <h3
                                        className="font-montserrat"
                                        onClick={() => toggleCollapse(item.id)}
                                        style={{ color: '#BABABA', cursor: 'pointer', marginRight: '10px' }}
                                    >
                                        {item.isCollapsed ? <>&#9660;</> : <>&#9650;</>}
                                    </h3>
                                </div>
                                <hr style={{ borderColor: '#BABABA' }} />
                                {!item.isCollapsed && (
                                    <p className="font-montserrat fs-16 fw-300" style={{ color: '#fff' }}>
                                        {item.answer}
                                    </p>
                                )}
                            </div>
                        ))}
                    </div>
                </div>
            </div>
        </>
    )
}
